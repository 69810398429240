import React from "react";

import Img from "gatsby-image";
function Heading({ data }) {
  return (
    <div>
      <div className="sucStrHeroSec">
        <h1 className="sucStrHeroSecTitle">{data.title}</h1>
        <div className="sucStrHeroSecHeading">{data.heading}</div>
      </div>

      <div className="sucStrImg blogheadImg">
        <div className="sucStrImgCont">
          <div className="headImg2">
            {/* <Img fixed={data.image.localFile.childImageSharp.fixed} alt="blog"  /> */}
            {data.image &&
              data.image.localFile &&
              data.image.localFile.childImageSharp && (
                <Img
                  fixed={data.image.localFile.childImageSharp.fixed}
                  alt="blog"
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
