import React from 'react'
import ReactMarkdown from 'react-markdown'

function About({ data }) {

    return (
        <div>
            {data.title !== "SilverRail Technologies" && data.title !== "Opus Bilprovning" && (
                <div>
                    <div className="imgnetWrap">
                    <div className="imgnetBox">
                        <div>
                        <img alt={data.title} height={50} src={data.image?.url} />
                        </div>
                        <div className="imgnetTitel">
                        {data.title}
                        </div>
                    </div>
                    </div>
                </div>
                )}

            <div className="sucStrAbtcont abtstoryWrap">
                <div className="sucStrAbttitle">
                    <div className="sucStrAbttitleCont">{data.aboutCompanyTitle}</div>
                </div>
                <div className="sucStrAbtDes"><div className="sucStrAbtList">

                    <ReactMarkdown>
                        {data.aboutCompany}
                    </ReactMarkdown>
                </div>
                </div>
            </div>
            <div className="sucStrAbtcont abtstoryWrap">
                <div className="sucStrAbttitle">
                    <div className="sucStrAbttitleCont"> {data.whyWorkTitle}</div>
                </div>
                <div className="sucStrAbtDes noBorder"><div className="sucStrAbtList">

                    <ReactMarkdown>
                        {data.whyWork}
                    </ReactMarkdown>
                </div>
                </div>
            </div>


        </div>
    )
}

export default About
